<template>
  <DisplayView v-if="ready"
    :companyId="companyId" 
    :page="page"
    @click="withButtonPressed" 
    @withButtonPressed="withButtonPressed" 
    @withValue="withValue"></DisplayView>
</template>

<script>
import ContentUtils from '@/utils/ContentUtils.js';

import DisplayView from '../../../../admin/views/shared/details/DisplayView.vue';
export default {
  name: 'load-company-view',
  components: {
    DisplayView,
  },
  data() {
    return {
        ready: false,
        companyId: null,
        page: null,
      };
  },
  props: {
  },
  mounted() {
    this.companyId = ContentUtils.baseDecode(this.$route.query.companyId);
    this.page = ContentUtils.baseDecode(this.$route.query.page);
    this.ready = true;
  },
  methods: {
    withValue: function(event) {
      this.$emit('withValue', event);
    },
    withButtonPressed: function(event) {
      this.$emit('withButtonPressed', event);
    },
  }
}
</script>
